import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useMemo, useCallback, useState } from 'react';

import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useMetricsStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import { omit } from '@core/utils/lodash-micro';
import { isLogOlderThan30Days } from '@core/utils/metrics';

import LogDetailPopover from '@ui/Metrics/LogDetailPopover';
import Notification, { notify } from '@ui/Notification';
import ReactTable, { useTablePreferences } from '@ui/ReactTable';

import useMetricsExport from '../hooks/useMetricsExport';

const MetricsPageTable = ({ isReadyToFetchData, isSuperhub }) => {
  const [updateQuery, query, graphQuery, tableQuery, isDemo, metricsPageConfig] = useMetricsStore(s => [
    s.updateQuery,
    s.query,
    s.graphQuery,
    s.tableQuery,
    s.myDevelopers.filters.demo,
    s.metricsPageConfig,
  ]);

  const { title, exportSource } = metricsPageConfig;
  const [pages, setPages] = useState(0);
  const [selectedLogMeta, setSelectedLogMeta] = useState(null);

  const isLogTable = useMemo(() => ['Top Endpoints', 'API Calls', 'API Errors'].includes(title), [title]);

  const logDetailQuery = useMemo(() => {
    if (!selectedLogMeta) return '';

    return qs.stringify({
      createdAt: new Date(selectedLogMeta.createdAt).toISOString(),
      groupId: selectedLogMeta.groupId,
      demo: Boolean(isDemo),
    });
  }, [selectedLogMeta, isDemo]);

  const { data, error: logError } = useMetricsAPI(
    `requests/${selectedLogMeta?.id}?${logDetailQuery}`,
    !!selectedLogMeta?.id,
  );

  // Hook that creates export job, polls for export job status and handles notifications + downloading file
  const { canExport, createExportJob, exportJobId } = useMetricsExport();

  const selectedLog = data?.log;

  const fetchTableQuery = useMemo(() => {
    /**
     * The /list fetch needs to build params from:
     * - any table options (any graph facets OR pagination params applied)
     * - any graph options (base routeConfig groupBy params)
     * - base query params (date range, etc.)
     * */
    const nextQuery = {
      ...tableQuery,
      ...graphQuery,
      ...query,
      demo: Boolean(isDemo),
    };

    // Don't send certain query params for non /requests endpoints
    if (metricsPageConfig.endpoint !== 'requests') {
      delete nextQuery.development;
      delete nextQuery.tryItNow;
    }

    return nextQuery;
  }, [tableQuery, graphQuery, query, isDemo, metricsPageConfig.endpoint]);

  const table = useMetricsAPI(
    `${metricsPageConfig.endpoint}/list?${qs.stringify(fetchTableQuery, stringifyOptions)}`,
    isReadyToFetchData,
  );

  const pageSize = fetchTableQuery.pageSize || 30;
  const page = fetchTableQuery.page || 0;

  const { defaultSort, columns, visibility: defaultVisibility, prefsName } = metricsPageConfig.table || {};

  const { columnVisibility, setColumnVisibility, columnOrder, setColumnOrder } = useTablePreferences({
    defaultVisibility,
    prefsName,
  });

  useEffect(() => {
    if (table.isLoading) return;
    const totalCount = parseInt(table?.headers?.['x-total-count'], 10) || 0;
    const totalPages = Math.ceil(totalCount / pageSize);
    setPages(totalPages);
  }, [pageSize, table?.headers, table.isLoading]);

  const filterByEmail = useCallback(
    email => {
      updateQuery('query', { userSearch: email });
    },
    [updateQuery],
  );

  const updatePage = useCallback(
    selectedPage => {
      updateQuery('tableQuery', { page: selectedPage });
    },
    [updateQuery],
  );

  const closeLog = useCallback(
    targetElement => {
      // If the click is inside the table, don't close the log because it's likely a click to expand another log
      if (!!selectedLogMeta?.id && targetElement?.closest('[class^="ReactTable-"]')) return;

      setSelectedLogMeta(null);
    },
    [selectedLogMeta],
  );

  const onRowClick = useCallback(row => {
    // Don't show log detail popover if log is older than 30 days
    if (isLogOlderThan30Days(row.original.createdAt)) {
      notify(<Notification>Request log details not available after 30 days</Notification>);
      return;
    }

    const { id, groupId, createdAt } = row.original;
    setSelectedLogMeta({ id, groupId, createdAt });
  }, []);

  const handleExport = useCallback(async () => {
    // Omit filters that don't apply to exports
    const selectFilters = omit(fetchTableQuery, ['page', 'pageSize', 'includeTrends', 'sort', 'direction']);
    const rangeLength = selectFilters.rangeLength;

    const body = {
      ...selectFilters,
      exportSource,
      development: selectFilters?.development === 'true',
      tryItNow: selectFilters?.tryItNow === 'true',
      // Always send range length as number
      ...(rangeLength ? { rangeLength: Number(rangeLength) } : {}),
    };

    // Filter out any null values
    Object.keys(body).forEach(key => {
      if (body[key] === null) delete body[key];
    });

    createExportJob(body, pages);
  }, [createExportJob, exportSource, pages, fetchTableQuery]);

  return (
    <>
      <ReactTable
        boxProps={isSuperhub ? { theme: 'dark-on-black' } : {}}
        className="MetricsPage-table"
        columnOptions={{
          visibility: columnVisibility,
          order: columnOrder,
        }}
        columns={columns}
        data={table.data}
        error={!!table.error}
        highlightRowOnClick={!!isLogTable && !!selectedLogMeta?.id}
        includeColumnSelector
        isCompact
        isExportLoading={!!canExport && !!exportJobId}
        isLoading={table.isLoading}
        meta={{
          filterByEmail,
        }}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
        onExport={canExport ? handleExport : undefined}
        onPageChange={updatePage}
        onRowClick={isLogTable ? onRowClick : null}
        page={page}
        pages={pages}
        showWithCard
        sort={defaultSort}
        theme={isSuperhub ? 'dark' : undefined}
      />

      {!!isLogTable && (
        <LogDetailPopover hasError={!!logError} log={selectedLog} onClose={closeLog} open={!!selectedLogMeta?.id} />
      )}
    </>
  );
};

MetricsPageTable.propTypes = {
  isReadyToFetchData: PropTypes.bool,
  /** Whether table is being used in Superhub (so table elements can have dark-on-black styling) */
  isSuperhub: PropTypes.bool,
};

export default MetricsPageTable;

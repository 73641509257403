import { format } from 'date-fns';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import { FixedSizeList as VirtualList } from 'react-window';

import useClassy from '@core/hooks/useClassy';

import Avatar from '@ui/Avatar';
import Badge from '@ui/Badge';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';

import classes from './style.module.scss';

const VIRTUAL_LIST_ITEM_SIZE = 30;

interface Props {
  apiKeys?: string[];
  email?: string;
  firstSeen?: string;
  lastSeen?: string;
  logo?: string;
  name?: string;
  onKeyClick: (apiKey: string) => void;
  /** Optional override prop for <Badge> theme */
  theme?: 'dark';
}

const KeyUsageDropdown = ({ apiKeys, firstSeen, lastSeen, name, onKeyClick, logo, email, theme }: Props) => {
  const bem = useClassy(classes, 'KeyUsageDropdown');

  const formattedDate = useCallback((d: string) => {
    const date = new Date(d);
    return format(date, 'MM/dd/yyyy');
  }, []);

  const handleNavigateToKeyInsight = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, apiKey: string) => {
      e.stopPropagation();
      onKeyClick(apiKey);
    },
    [onKeyClick],
  );

  const listHeight = useMemo(() => {
    if (!apiKeys?.length) return 0;
    return apiKeys.length >= 6 ? VIRTUAL_LIST_ITEM_SIZE * 6 : VIRTUAL_LIST_ITEM_SIZE * apiKeys.length;
  }, [apiKeys]);

  const keysText = useMemo(() => {
    if (!apiKeys?.length) return '';
    const countText = pluralize('API key', apiKeys.length, true);
    return name ? `${countText} at ${name}` : `${countText} used by ${email}`;
  }, [apiKeys, email, name]);

  if (!apiKeys?.length) return <></>;

  return (
    <Dropdown className={bem('&')} justify="center" trigger="hover">
      <Badge kind={theme || 'light'}>{apiKeys.length}</Badge>
      <Menu className={bem('-menu')}>
        {!!(firstSeen || lastSeen) && (
          <MenuHeader>
            <Flex align="start" justify="between">
              <span>{name}</span>
              {!!logo && <Avatar imgSrc={logo} size="xs" />}
            </Flex>
          </MenuHeader>
        )}
        {!!firstSeen && (
          <MenuItem focusable={false}>
            <Flex gap="0" justify="start">
              <Icon className={bem('-menuIcon')} name="calendar" size="md" />
              <Flex gap="0" layout="col">
                <span>{formattedDate(firstSeen)}</span>
                <span className={bem('-meta-label')}>First Request</span>
              </Flex>
            </Flex>
          </MenuItem>
        )}
        {!!lastSeen && (
          <MenuItem focusable={false}>
            <Flex gap="0" justify="start">
              <Icon className={bem('-menuIcon')} name="calendar" size="md" />
              <Flex gap="0" layout="col">
                <span>{formattedDate(lastSeen)}</span>
                <span className={bem('-meta-label')}>Most Recent Request</span>
              </Flex>
            </Flex>
          </MenuItem>
        )}
        {!!(firstSeen || lastSeen) && <MenuDivider />}
        {!!(name || email) && <MenuHeader className={bem('-keysHeader')}>{keysText}</MenuHeader>}

        <VirtualList
          height={listHeight}
          itemCount={apiKeys.length}
          itemData={apiKeys}
          itemSize={VIRTUAL_LIST_ITEM_SIZE}
          width="100%"
        >
          {({ index, style }) => (
            <MenuItem
              alignIcon="right"
              color="blue"
              icon={<Icon name="arrow-right" size="md" />}
              onClick={e => handleNavigateToKeyInsight(e, apiKeys[index])}
              style={style}
            >
              <ObfuscatedAPIKey
                allowCopy={false}
                allowExpansion={false}
                apiKey={apiKeys[index]}
                conceal="before"
                displayLength={4}
              />
            </MenuItem>
          )}
        </VirtualList>
      </Menu>
    </Dropdown>
  );
};

export default React.memo(KeyUsageDropdown);

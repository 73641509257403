import type { TableMeta } from './tableModels';
import type { Row, RowData, ColumnDef } from '@tanstack/react-table';

import parseLinkHeader from 'parse-link-header';
import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import { MyDevelopersSubrouteType, TableType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';
import type { APIKeyRequestRow, RecentRequestRow, HeaderLinks, MyDevelopersRouteParams } from '@core/types/metrics';
import { omit } from '@core/utils/lodash-micro';

import useMetricsExport from '@routes/Dash/Project/Metrics/hooks/useMetricsExport';

import Button from '@ui/Button';
import ReactTable, { useTablePreferences } from '@ui/ReactTable';
import type { ColumnSort } from '@ui/ReactTable/types';

import classes from './style.module.scss';
import { defaultColumn, tablePrefs } from './tableModels';

interface Props {
  className?: string;
  highlightRowOnClick?: boolean;
  /** Whether or not table is being rendered within Superhub panel */
  isSuperHub?: boolean;
  onRowClick?: (row: Row<APIKeyRequestRow | RecentRequestRow>) => void;
  /** Theme override prop for subcomponents like <ColumnSelector> */
  theme?: 'dark';
}

// @tanstack/react-table types available: packages/react/node_modules/@tanstack/table-core/build/types/index.d.ts
const RecentRequestTable = ({ className, highlightRowOnClick, isSuperHub, onRowClick, theme }: Props) => {
  const bem = useClassy(classes, 'RecentRequestTable');
  const { project } = useContext(ProjectContext);
  const { type } = useParams<MyDevelopersRouteParams>();

  const [
    activeAPIKey,
    hasAPIKeyInRoute,
    filters,
    activeSegment,
    hasChangedFromInitialFilters,
    queryParams,
    updateFilters,
    generateRequestPath,
    isReadyToFetchWithSegments,
    resetFilters,
    updateTableColumns,
  ] = useMetricsStore(s => [
    s.myDevelopers.activeAPIKey,
    s.myDevelopers.hasAPIKeyInRoute,
    s.myDevelopers.filters,
    s.myDevelopers.activeSegment,
    s.myDevelopers.getHasChangedFromInitialFilters(),
    s.myDevelopers.getQueryParams(),
    s.myDevelopers.updateFilters,
    s.myDevelopers.generateRequestPath,
    s.myDevelopers.getReadyToFetch({ waitForSegments: true }),
    s.myDevelopers.resetFilters,
    s.myDevelopers.updateTableColumns,
  ]);

  // Hook that creates export job, polls for export job status and handles notifications + downloading file
  const { canExport, createExportJob, exportJobId } = useMetricsExport();

  const { buildUrl, navigate } = useMyDevelopers();

  const { page = 0, sort, direction } = filters;
  const tableType = hasAPIKeyInRoute ? TableType.APIKeyInsights : TableType.RecentRequests;

  const {
    columns,
    endpoint,
    sort: defaultSortColumn,
    direction: defaultSortDirection,
    order: defaultColumnOrder,
    visibility: defaultColumnVisibility,
    prefsName,
  } = tablePrefs[tableType];

  const { columnVisibility, setColumnVisibility, columnOrder, setColumnOrder, resetTablePrefs } = useTablePreferences({
    defaultOrder: defaultColumnOrder,
    defaultVisibility: defaultColumnVisibility,
    prefsName,
    // Don't save table prefs in LocalStorage when there's an active segment (only Recent Requests)
    skipLocalStorageUpdate: !!activeSegment,
  });

  const [totalPages, setTotalPages] = useState(1);

  const { data, headers, isLoading, isValidating, error } = useMetricsAPI<APIKeyRequestRow[] | RecentRequestRow[]>(
    generateRequestPath(endpoint, queryParams),
    isReadyToFetchWithSegments,
  );

  // Update column order and visibility when active segment changes
  useEffect(() => {
    if (!activeSegment) {
      resetTablePrefs();
      return;
    }

    const segmentColumnOrder = activeSegment.columns.map(col => col.columnId);

    const segmentColumnVisibility = {};
    activeSegment.columns.forEach(col => {
      segmentColumnVisibility[col.columnId] = col.visible;
    });

    setColumnOrder(segmentColumnOrder);
    setColumnVisibility(segmentColumnVisibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSegment]);

  useEffect(() => {
    // Pass column visiblity and column order into context
    const cols = columnOrder.map((column, index) => {
      return {
        columnId: column,
        visible: columnVisibility[column] !== false,
        order: index,
      };
    });

    updateTableColumns(cols);
  }, [columnVisibility, columnOrder, updateTableColumns]);

  useEffect(() => {
    if (!isLoading && !data?.length) setTotalPages(1);
    else {
      try {
        const { link } = headers;
        const { last } = parseLinkHeader(link) as unknown as HeaderLinks;

        const parsedTotalPageCount = parseInt(last?.page, 10);
        if (!Number.isNaN(parsedTotalPageCount)) setTotalPages(parsedTotalPageCount + 1);
        else setTotalPages(1);
      } catch (e) {
        // do nothing
      }
    }
  }, [isLoading, headers, data]);

  const updatePage = useCallback(
    selectedPage => {
      updateFilters({ page: selectedPage });
    },
    [updateFilters],
  );

  const onSortChange = useCallback(
    (newSort: ColumnSort) => {
      updateFilters({ sort: newSort.column, direction: newSort.direction });
    },
    [updateFilters],
  );

  const handleExport = useCallback(() => {
    // Omit filters that don't apply to exports
    const selectFilters = omit(filters, ['page', 'pageSize', 'includeTrends', 'sort', 'direction']);
    const rangeLength = selectFilters.rangeLength;

    const body = {
      ...selectFilters,
      // Export source for APIKeyInsights needs to be requests instead of developers
      exportSource: activeAPIKey ? 'request' : 'developers',
      // Status needs to be sent as an array of numbers, not strings on export POST
      status: (filters.status || []).map(item => Number(item)),
      // Always send range length as number
      ...(rangeLength ? { rangeLength: Number(rangeLength) } : {}),
      ...(activeAPIKey ? { groupId: activeAPIKey } : {}),
    };

    // Filter out any null values
    Object.keys(body).forEach(key => {
      if (body[key] === null) delete body[key];
    });

    createExportJob(body, totalPages);
  }, [activeAPIKey, createExportJob, filters, totalPages]);

  const navigateToKeyInsight = useCallback(
    (apiKey: string) => {
      const url = buildUrl({ type: MyDevelopersSubrouteType.Key, identifier: apiKey });
      navigate(url);
    },
    [buildUrl, navigate],
  );

  const navigateToUserPage = useCallback(
    (email: string) => {
      const url = buildUrl({ type: MyDevelopersSubrouteType.User, identifier: email });
      navigate(url);
    },
    [buildUrl, navigate],
  );

  const customEmptyRender = useMemo(() => {
    if (activeSegment) {
      return (
        <>
          No data found
          <strong className={bem('-table-empty-text')}>Try updating your filters</strong>
        </>
      );
    }

    if (hasChangedFromInitialFilters) {
      return (
        <>
          No data found
          <Button kind="destructive" onClick={() => resetFilters()} size="sm" text>
            <i className="icon icon-x"></i>
            <span>Try clearing your filters</span>
          </Button>
        </>
      );
    }

    return null;
  }, [bem, activeSegment, hasChangedFromInitialFilters, resetFilters]);

  const disableRowClick = useMemo(() => type !== 'key' && project.flags.developerViewUsersData, [project.flags, type]);

  return (
    <ReactTable
      boxProps={isSuperHub ? { theme: 'dark-on-black' } : {}}
      className={bem('&', className)}
      columnOptions={{
        visibility: columnVisibility,
        order: columnOrder,
      }}
      columns={columns as ColumnDef<RowData>[]}
      customEmptyRender={customEmptyRender}
      data={data}
      defaultColumn={defaultColumn as Partial<ColumnDef<RowData>>}
      hasError={!!error}
      highlightRowOnClick={highlightRowOnClick}
      includeColumnSelector
      isExportLoading={!!canExport && !!exportJobId}
      isLoading={isLoading || isValidating}
      meta={
        {
          navigateToKeyInsight,
          navigateToUserPage,
          theme,
        } as TableMeta
      }
      onColumnOrderChange={setColumnOrder}
      onColumnVisibilityChange={setColumnVisibility}
      onExport={canExport ? handleExport : undefined}
      onPageChange={updatePage}
      onRowClick={!disableRowClick ? onRowClick : undefined}
      onSort={onSortChange}
      page={page || 0}
      pages={totalPages}
      showWithCard={isSuperHub}
      sort={{
        column: sort || defaultSortColumn,
        direction: direction || defaultSortDirection,
      }}
      theme={theme}
    />
  );
};

export default RecentRequestTable;

import React, { useCallback, useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { HTTPMethod } from '@core/types';

import APIMethod from '@ui/API/Method';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuDivider, MenuHeader } from '@ui/Menu';

import styles from './style.module.scss';

interface Props {
  /** Whether filter is being used in Superhub (so dropdown can have dark-on-black styling) */
  isSuperhub?: boolean;
  onChange: () => void;
}

const methods: { color: string; label: string; value: HTTPMethod }[] = [
  {
    color: '#C71B29',
    label: 'Delete',
    value: 'delete',
  },
  {
    color: '#0E9B71',
    label: 'Get',
    value: 'get',
  },
  {
    color: '#DF7D03',
    label: 'Patch',
    value: 'patch',
  },
  {
    color: '#0272D9',
    label: 'Post',
    value: 'post',
  },
  {
    color: '#674EAD',
    label: 'Put',
    value: 'put',
  },
];

const FilterMethod = ({ isSuperhub, onChange }: Props) => {
  const bem = useClassy(styles, 'MetricsFilter-Method');
  const [activeFilter, setActiveFilter] = useState<HTTPMethod | null>(null);
  const [updateQuery, query] = useMetricsStore(s => [s.updateQuery, s.query]);

  const getActiveConfig = useCallback(
    () => methods.find(method => method.value === activeFilter) || null,
    [activeFilter],
  );

  useEffect(() => {
    setActiveFilter((query.method as HTTPMethod) || null);
  }, [query]);

  const handleChange = useCallback(
    filter => {
      updateQuery('query', { method: filter });
      onChange();
    },
    [onChange, updateQuery],
  );

  const isActive = activeFilter !== null;
  const activeMethod = getActiveConfig()?.value;

  return (
    <Dropdown align="bottom" className={bem('&')} justify="start" sticky>
      <Button
        className={bem(isSuperhub && '-btn-dark-on-black')}
        dropdown
        kind={isActive ? 'primary' : 'secondary'}
        outline={!isActive}
        size="sm"
      >
        <Flex align="center" gap="xs">
          Method {!!isActive && !!activeMethod && <APIMethod fixedWidth type={activeMethod} />}
        </Flex>
      </Button>
      <Menu>
        <MenuHeader>Methods</MenuHeader>
        {methods.map(method => (
          <MenuItem
            key={method.label}
            active={activeFilter === method.value}
            onClick={() => handleChange(method.value)}
          >
            <Flex align="center" gap="sm" justify="start">
              {!!method.value && <APIMethod fixedWidth type={method.value} />}
              <span>{method.label}</span>
            </Flex>
          </MenuItem>
        ))}
        <MenuDivider />
        <MenuItem active={activeFilter === null} onClick={() => handleChange(null)}>
          Show All
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default FilterMethod;

import copy from 'clipboard-copy';
import { format } from 'date-fns';
import queryString from 'qs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';
import type { Segment } from '@core/types/metrics';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import EmojiPicker from '@ui/EmojiPicker';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import Owlmoji from '@ui/Owlmoji';
import Timestamp from '@ui/Timestamp';

import classes from './style.module.scss';

interface Props {
  deleteSegment: (segment: Segment) => void;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  navigateToSegment: (segment: Segment) => void;
  segment: Segment;
  updateSegment: (segment: Segment) => void;
}

const SegmentPill = ({
  isActive,
  isFirst,
  isLast,
  segment,
  deleteSegment,
  updateSegment,
  navigateToSegment,
}: Props) => {
  const bem = useClassy(classes, 'Segments');

  const [customerUsage, tableColumns, hasChangedFromActiveSegment, segmentQueryParams] = useMetricsStore(s => [
    s.customerUsage,
    s.myDevelopers.tableColumns,
    s.myDevelopers.getHasChangedFromActiveSegment(),
    s.myDevelopers.getSegmentQueryParams(),
  ]);

  const { buildUrl } = useMyDevelopers();

  const { emoji, title, lastUpdated } = segment;

  const defaultCopyText = 'Copy Link';
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentEmoji, setCurrentEmoji] = useState(emoji);
  const [copyText, setCopyText] = useState(defaultCopyText);

  const shouldDisable = useMemo(() => {
    const overLimit = customerUsage.overLimit;

    // If they are paying, but over their usage limit
    // Check segment time range (i.e. anything other than last 24 hours)
    // Disable segments that are out of this range
    if (overLimit) {
      const { rangeLength } = queryString.parse(segment?.search);
      return rangeLength !== '24';
    }

    // If a customer is not paying, they shouldn't have any segments anyway
    // But disable access
    return false;
  }, [segment?.search, customerUsage?.overLimit]);

  const titleOrEmojiHasChanged = useMemo(() => {
    return currentTitle !== title || currentEmoji !== emoji;
  }, [currentEmoji, currentTitle, emoji, title]);

  const segmentHasChanged = useMemo(() => {
    return (isActive && hasChangedFromActiveSegment) || titleOrEmojiHasChanged;
  }, [isActive, hasChangedFromActiveSegment, titleOrEmojiHasChanged]);

  const debouncedUpdateTitle = useDebounced((value: string) => {
    setCurrentTitle(value);
  }, 300);

  const onTitleChange = useCallback(
    e => {
      const { value } = e.target;
      debouncedUpdateTitle(value);
    },
    [debouncedUpdateTitle],
  );

  const onEmojiChange = useCallback(newEmoji => {
    setCurrentEmoji(newEmoji);
  }, []);

  const resetEmojiAndTitle = useCallback(() => {
    setCurrentTitle(title);
    setCurrentEmoji(emoji);
  }, [emoji, title]);

  const saveSegment = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement | HTMLSpanElement>) => {
      e?.stopPropagation();

      updateSegment({
        ...segment,
        columns: tableColumns,
        search: segmentQueryParams,
        title: currentTitle,
        emoji: currentEmoji,
      });
    },
    [currentTitle, currentEmoji, segment, segmentQueryParams, tableColumns, updateSegment],
  );

  const handleKey = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        case 'Enter':
          ev.stopPropagation();
          saveSegment();
          break;
        default:
      }
    },
    [saveSegment],
  );

  const handleCopy = useCallback(() => {
    const path = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: segment.slug });
    const url = `${window.location.origin}${path}`;

    copy(url)
      .then(() => setCopyText('Copied!'))
      .catch(() => setCopyText('Unable to Copy'))
      .then(() => setTimeout(() => setCopyText(defaultCopyText), 1500));
  }, [buildUrl, segment.slug]);

  const handleOnBlur = useCallback(() => {
    // Reset title and emoji when dropdown is closed from hover
    if (!isActive && titleOrEmojiHasChanged) {
      resetEmojiAndTitle();
    }
  }, [isActive, titleOrEmojiHasChanged, resetEmojiAndTitle]);

  useEffect(() => {
    // Reset title and emoji when navigating to another segment
    if (!isActive) {
      resetEmojiAndTitle();
    }
  }, [isActive, resetEmojiAndTitle]);

  return (
    <Dropdown justify="start" onBlur={handleOnBlur} trigger={shouldDisable ? '' : 'hover'}>
      <Button
        circular
        className={bem('-segmentPill')}
        disabled={shouldDisable}
        kind={isActive ? 'primary' : 'minimum'}
        onClick={() => navigateToSegment(segment)}
        outline
        size="sm"
      >
        {!!segmentHasChanged && (
          <span
            className={bem('-smallSaveButton')}
            onClick={saveSegment}
            onKeyDown={handleKey}
            role="button"
            tabIndex={0}
          >
            Save
          </span>
        )}
        <span className={bem('-segmentEmoji')}>
          <Owlmoji>{currentEmoji}</Owlmoji>
        </span>
        <span className={bem('-segmentTitle')}>{currentTitle}</span>
        {!!isActive && <Icon className={bem('-segmentIcon')} color="gray60" name="more-vertical" />}
      </Button>

      <Menu>
        <MenuItem className={bem('-inputMenuItem')} focusable={false}>
          <Dropdown sticky>
            <Button outline size="sm">
              <Owlmoji>{currentEmoji}</Owlmoji>
            </Button>
            <Box kind="pop" style={{ maxHeight: 325, maxWidth: 312, overflowY: 'auto', padding: 5 }}>
              <EmojiPicker onClick={onEmojiChange} value={currentEmoji} />
            </Box>
          </Dropdown>
          <Input
            id="segmentTitle"
            name="segmentTitle"
            onChange={onTitleChange}
            placeholder="Segment title"
            size="sm"
            value={currentTitle}
          />
        </MenuItem>
        <MenuDivider />
        <MenuItem
          disabled={isFirst}
          icon={<Icon name="arrow-left" />}
          onClick={() => updateSegment({ ...segment, order: segment.order - 1 })}
        >
          Move Left
        </MenuItem>
        <MenuItem
          disabled={isLast}
          icon={<Icon name="arrow-right" />}
          onClick={() => updateSegment({ ...segment, order: segment.order + 1 })}
        >
          Move Right
        </MenuItem>
        <MenuDivider />
        <MenuItem icon={<Icon name="link" />} onClick={handleCopy}>
          {copyText}
        </MenuItem>
        {!!segmentHasChanged && (
          <>
            <MenuItem icon={<Icon name="save" />} onClick={saveSegment}>
              Save Changes
            </MenuItem>
          </>
        )}
        <MenuDivider />
        <MenuItem color="red" icon={<Icon name="trash" />} onClick={() => deleteSegment(segment)}>
          Delete Segment
        </MenuItem>
        <MenuDivider />
        <MenuItem className={bem('-lastEditedMenuItem')} focusable={false}>
          <span>Last edited by {lastUpdated.name || 'N/A'}</span>
          <span>
            On{' '}
            {lastUpdated.updatedAt ? (
              <Timestamp formatter={date => format(date, 'MM/dd/yyyy h:mm aa')} value={lastUpdated.updatedAt} />
            ) : (
              'N/A'
            )}
          </span>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default SegmentPill;

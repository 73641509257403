import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';

import { useSegmentContext } from '../Context';
import classes from '../index.module.scss';

export interface SegmentItemProps {
  /**
   * The index of the item in the segment group. This is applied automatically by the `Segment` component.
   */
  _index?: number;
  /**
   * A slot to display on the right side of the item, helpful for secondary actions that need to be rendered adjacent to
   * the primary button/link element.
   */
  afterSlot?: React.ReactNode;
  /**
   * Content of the item.
   */
  children: React.ReactNode;
  /**
   * The className to apply to the item.
   */
  className?: string;
  /**
   * A Dropdown menu to display when the item is clicked.
   */
  dropdownMenu?: React.ReactNode;
  /**
   * Callback fired when the item is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * A route to link to when the item is clicked.
   */
  to?: string;
  /**
   * Value of the item.
   */
  value?: string;
}

/**
 * A single item in a `Segment` component.
 */
export default function SegmentItem({
  _index,
  children,
  className,
  onClick,
  to,
  dropdownMenu,
  afterSlot,
}: SegmentItemProps) {
  const ref = useRef<HTMLDivElement>(null);
  const bem = useClassy(classes, 'SegmentItem');
  const { selectedIndex, setSelectedIndex, setHighlightPosition } = useSegmentContext();

  useEffect(() => {
    // register resize observer on ref.current and its parent
    const element = ref.current;
    const parentElement = element?.parentElement;

    if (element && parentElement) {
      const callback = () => {
        if (selectedIndex === _index) {
          // Setting the highlight position will trigger a re-render, so we need to do it in the next frame.
          // This prevents an infinite ResizeObserver callback loop.
          requestAnimationFrame(() => {
            setHighlightPosition({ left: element.offsetLeft, width: element.offsetWidth });
          });
        }
      };

      const observer = new ResizeObserver(callback);
      observer.observe(element);
      observer.observe(parentElement);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [_index, selectedIndex, setHighlightPosition]);

  return (
    <div
      ref={ref}
      aria-selected={selectedIndex === _index}
      className={bem('&', selectedIndex === _index && '_active', className)}
      role="tab"
    >
      {to ? (
        <NavLink className={bem('-unset', '-triggerElement')} to={to}>
          {children}
        </NavLink>
      ) : dropdownMenu ? (
        <Dropdown appendTo={document.body} clickInToClose justify="start">
          <button className={bem('-unset', '-triggerElement')}>
            {children}
            <Icon className={bem('-chevron')} name="chevron-down" />
          </button>
          {dropdownMenu}
        </Dropdown>
      ) : (
        <button
          className={bem('-unset', '-triggerElement')}
          onClick={event => {
            onClick?.(event);
            setSelectedIndex(_index);
          }}
        >
          {children}
        </button>
      )}
      {!!afterSlot && <div className={bem('-afterSlot')}>{afterSlot}</div>}
    </div>
  );
}

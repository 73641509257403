import type { Row } from '@tanstack/react-table';

import { addDays } from 'date-fns';
import qs from 'qs';
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { ProjectContext } from '@core/context';
import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useDashMiniSidebar from '@core/hooks/useDashMiniSidebar';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore, InitializeMyDevelopers } from '@core/store';
import { ConnectMyDevelopersToRouter } from '@core/store/Metrics/MyDevelopers/ConnectMyDevelopersToRouter';
import type {
  MyDevelopersRouteParams,
  APIKeyLogResponse,
  APIKeyRequestRow,
  MetricsFilter,
  RecentRequestRow,
  ApiKeyMeta,
  APIRequestData,
  MetricsFilters,
} from '@core/types/metrics';
import { isLogOlderThan30Days } from '@core/utils/metrics';

import MetricsView from '@routes/Dash/Project/Metrics/View';
import MyDevelopersUsers from '@routes/MyDevelopers/Users';

import Flex from '@ui/Flex';
import Filters from '@ui/Metrics/Filters';
import LogDetailPopover from '@ui/Metrics/LogDetailPopover';
import LogsLimitPopover from '@ui/Metrics/LogsLimitPopover';
import Notification, { notify } from '@ui/Notification';
import Spinner from '@ui/Spinner';

import DemoBanner from './DemoBanner';
import RecentRequestTable from './RecentRequestTable';
import Segments from './Segments';
import SetupBanner from './SetupBanner';
import styles from './style.module.scss';
import Title from './Title';
import UsersTitle from './Title/UsersTitle';

interface WrapperProps {
  children: React.ReactNode;
  filters: MetricsFilters;
  hasAPIKeyInRoute: boolean;
  includeTryItData: boolean;
  isHub: boolean;
  showOnboardingSetupBanner: boolean;
  showUsersPageView: boolean;
}

const Wrapper: React.FC<WrapperProps> = ({
  isHub,
  children,
  filters,
  showOnboardingSetupBanner,
  includeTryItData,
  showUsersPageView,
  hasAPIKeyInRoute,
}) => {
  const bem = useClassy(styles, 'MyDevelopers');

  // SuperHub layout for MyDevelopers sits within SuperHub panel
  if (isHub) {
    return <div className={bem('&', '-superhub')}>{children}</div>;
  }

  // Dash layout for MyDevelopers is wrapped within MetricsView
  return (
    <>
      <DemoBanner showBanner={!!filters.demo} />
      <MetricsView
        containerClassName={bem('&')}
        dateRangeOptions={{
          isMyDevelopers: !showUsersPageView,
          showTryItBadge: !filters.demo && !!showOnboardingSetupBanner && includeTryItData,
        }}
        hideSearch={!!hasAPIKeyInRoute}
        hideSettings
        searchOptions={{
          isUserPage: showUsersPageView,
          isMyDevelopers: !showUsersPageView,
        }}
        size="xl"
        title={showUsersPageView ? <UsersTitle /> : <Title />}
      >
        {children}
      </MetricsView>
    </>
  );
};

interface MyDevelopersProps {
  /** Whether or not MyDevelopers is being used within Hub or Dash (defaults to Dash) */
  isHub: boolean;
}

const MyDevelopers = ({ isHub }: MyDevelopersProps) => {
  // NOTE(12/2/2022): This is a temporary hack and fix to collapse the Angular
  // sidebar down whenever this component is rendered. Eventually, when we
  // replace the L1 Angular sidebar, we can remove this hook entirely.
  useDashMiniSidebar();

  const bem = useClassy(styles, 'MyDevelopers');
  const { project } = useContext(ProjectContext);

  const { type } = useParams<MyDevelopersRouteParams>();
  const { search } = useLocation();

  const [
    customerUsage,
    includeTryItNow,
    isSetupComplete,
    activeAPIKey,
    hasAPIKeyInRoute,
    filters,
    minimalQueryParams,
    rangeQueryParams,
    hasChangedFromInitialFilters,
    updateFilters,
    removeFilter,
    generateRequestPath,
    isReadyToFetchWithSegments,
  ] = useMetricsStore(s => [
    s.customerUsage,
    s.includeTryItNow,
    s.isSetupComplete,
    s.myDevelopers.activeAPIKey,
    s.myDevelopers.hasAPIKeyInRoute,
    s.myDevelopers.filters,
    s.myDevelopers.getMinimalQueryParams(),
    s.myDevelopers.getRangeQueryParams(),
    s.myDevelopers.getHasChangedFromInitialFilters(),
    s.myDevelopers.updateFilters,
    s.myDevelopers.removeFilter,
    s.myDevelopers.generateRequestPath,
    s.myDevelopers.getReadyToFetch({
      waitForSegments: true,
    }),
  ]);
  const { buildUrl, navigate } = useMyDevelopers();

  const [selectedLogMeta, setSelectedLogMeta] = useState<ApiKeyMeta>(null);
  const [selectedLog, setSelectedLog] = useState<APIKeyLogResponse['log']>();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [isLogsLimitPopoverOpen, setIsLogsLimitPopoverOpen] = useState(() => {
    // Retrieve the last closed timestamp from sessionStorage
    const lastClosedTimestamp = sessionStorage.getItem('logsLimitPopoverLastClosed');
    // Default to true if no timestamp is found
    if (!lastClosedTimestamp) {
      return true;
    }

    const now = new Date();
    const lastClosed = new Date(lastClosedTimestamp);
    const twoDaysLater = addDays(lastClosed, 2);

    // If current time is less than two days after last closed, keep popover closed
    return now >= twoDaysLater;
  });

  const showUsersPageView = !!project.flags.developerViewUsersData && type === 'user';

  const handleSetIsLogsLimitPopoverOpen = useCallback(isOpen => {
    setIsLogsLimitPopoverOpen(isOpen);
    if (!isOpen) {
      // Store the current timestamp as an ISO string when the popover is closed
      const now = new Date().toISOString();
      sessionStorage.setItem('logsLimitPopoverLastClosed', now);
    }
  }, []);

  const isNearingLogLimit = useMemo(
    () =>
      customerUsage.sdk.monthToDate && customerUsage.limit
        ? customerUsage.sdk.monthToDate / customerUsage.limit > 0.75
        : false,
    [customerUsage?.sdk?.monthToDate, customerUsage.limit],
  );

  const endpoint = hasAPIKeyInRoute ? 'requests/filters' : 'developers/filters';

  // Fetch all available filters
  const { data: allAvailableFiltersData, isLoading } = useMetricsAPI<MetricsFilter[]>(
    generateRequestPath(endpoint, rangeQueryParams),
    isReadyToFetchWithSegments && !showUsersPageView,
  );

  // Fetch api requests
  const { data: apiRequestTrends } = useMetricsAPI<APIRequestData>(
    generateRequestPath('developers/historical', minimalQueryParams),
    isReadyToFetchWithSegments && !showUsersPageView,
  );

  // Fetch applicable filters based on current query params
  const { data: filtersData } = useMetricsAPI<MetricsFilter[]>(
    generateRequestPath(endpoint, minimalQueryParams),
    isReadyToFetchWithSegments && !showUsersPageView,
  );

  const logDetailQuery = useMemo(() => {
    if (!selectedLogMeta) return '';
    return qs.stringify({
      createdAt: selectedLogMeta.createdAt ? new Date(selectedLogMeta.createdAt).toISOString() : undefined,
      groupId: selectedLogMeta.groupId,
      demo: Boolean(filters.demo),
    });
  }, [selectedLogMeta, filters.demo]);

  const { data, error: logError } = useMetricsAPI<APIKeyLogResponse>(
    `requests/${selectedLogMeta?.id}?${logDetailQuery}`,
    !!selectedLogMeta?.id,
  );

  useEffect(() => {
    setSelectedLog(data?.log);
  }, [data]);

  const showAPIKeyDetail = useCallback(
    meta => {
      setSelectedLogMeta(meta);

      // Only toggle modal open if it's not already open
      if (!popoverOpen) {
        setPopoverOpen(true);
      }
    },
    [popoverOpen],
  );

  // If we've got a valid requestId param on mount, show the log detail popover
  useEffect(() => {
    if (!hasAPIKeyInRoute) return;

    const params = qs.parse(search, { ignoreQueryPrefix: true });

    const { requestId: reqId = '' } = params;

    if (reqId && uuidValidate(reqId as string)) {
      showAPIKeyDetail({
        id: reqId,
        groupId: activeAPIKey,
      });
    }
  }, [hasAPIKeyInRoute]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowClick = useCallback(
    (row: Row<APIKeyRequestRow | RecentRequestRow>) => {
      // Don't show log detail popover if log is older than 30 days
      if (isLogOlderThan30Days(row.original.createdAt)) {
        notify(<Notification>Request log details not available after 30 days</Notification>);
        return;
      }

      if (hasAPIKeyInRoute && 'id' in row.original) {
        const { id, groupId, createdAt } = row.original;
        showAPIKeyDetail({ id, groupId, createdAt });
        return;
      }

      // @todo remove feature flag
      if ('apiKey' in row.original) {
        // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
        const url = buildUrl({ type: MyDevelopersSubrouteType.Key, identifier: row.original.apiKey });
        navigate(url);
      }
    },
    [buildUrl, navigate, hasAPIKeyInRoute, showAPIKeyDetail],
  );

  const closeLog = useCallback(
    targetElement => {
      // If the click is inside the table, don't close the log because it's likely a click to expand another log
      if (!!selectedLog && targetElement?.closest('[class^="ReactTable-"]')) return;

      setPopoverOpen(false);
      setSelectedLogMeta(null);
    },
    [selectedLog],
  );

  const handleUpdateFilters = useCallback(
    updatedFilters => {
      updateFilters({
        ...updatedFilters.filters,
      });
    },
    [updateFilters],
  );

  const handleClearFilter = useCallback(
    (key, value) => {
      removeFilter(key, value);
    },
    [removeFilter],
  );

  const includeTryItData = includeTryItNow || false;

  // When onboarding hasn't been completed and "Include Try It" is not checked, we want to show the skeleton components
  const showOnboardingSetupBanner = !isSetupComplete;

  return (
    <Wrapper
      filters={filters}
      hasAPIKeyInRoute={hasAPIKeyInRoute}
      includeTryItData={includeTryItData}
      isHub={isHub}
      showOnboardingSetupBanner={showOnboardingSetupBanner}
      showUsersPageView={showUsersPageView}
    >
      {showUsersPageView ? (
        <MyDevelopersUsers isSuperhub={isHub} />
      ) : (
        <>
          {!hasAPIKeyInRoute && <Segments />}
          <div className={bem('-layout')}>
            <Filters
              activeFilters={filters}
              allFiltersData={allAvailableFiltersData}
              apiRequestTrends={apiRequestTrends}
              clearFilter={handleClearFilter}
              data={filtersData}
              hasAPIKeyInRoute={hasAPIKeyInRoute}
              hasChangedFromInitialFilters={hasChangedFromInitialFilters}
              hideSearch
              isLoading={isLoading}
              setFilters={handleUpdateFilters}
              theme={isHub ? 'dark' : undefined}
            />

            <div className={bem('-loading-wrapper')}>
              <SetupBanner showBanner={!isHub && !!showOnboardingSetupBanner} />
              <RecentRequestTable
                highlightRowOnClick={!!hasAPIKeyInRoute && popoverOpen}
                isSuperHub={isHub}
                onRowClick={onRowClick}
                theme={isHub ? 'dark' : undefined}
              />
            </div>

            {!!hasAPIKeyInRoute && (
              <LogDetailPopover hasError={!!logError} log={selectedLog} onClose={closeLog} open={popoverOpen} />
            )}
            {!!(customerUsage.overLimit || isNearingLogLimit) && !!isLogsLimitPopoverOpen && (
              <LogsLimitPopover
                isOpen={isLogsLimitPopoverOpen}
                nearLimit={isNearingLogLimit}
                overLimit={customerUsage.overLimit}
                setIsOpen={handleSetIsLogsLimitPopoverOpen}
              />
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
};

const MyDevelopersWrapper = ({ isHub = false }: MyDevelopersProps) => {
  const bem = useClassy(styles, 'MyDevelopers');

  const [isUsageReady, dateRanges] = useMetricsStore(s => [s.isUsageReady, s.dateRanges]);

  // Show loading spinner until we have customer usage data and date ranges established
  if (!isUsageReady || !dateRanges) {
    return (
      <Flex align="center" className={bem('-loading')} justify="center">
        <Spinner size="md" />
      </Flex>
    );
  }

  return (
    <ConnectMyDevelopersToRouter>
      <InitializeMyDevelopers>
        <MyDevelopers isHub={isHub} />
      </InitializeMyDevelopers>
    </ConnectMyDevelopersToRouter>
  );
};

export default MyDevelopersWrapper;

import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import MyDevelopers from '@routes/MyDevelopers';
import TopBar from '@routes/MyDevelopers/TopBar';
import BasePanel from '@routes/SuperHub/Layout/BasePanel';

import Flex from '@ui/Flex';

import styles from './index.module.scss';

const PANEL_MIN_COLLAPSE_HEIGHT = 450;

/**
 * Displays My Developers panel in the SuperHub layout
 */
function MyDevelopersPanel() {
  const bem = useClassy(styles, 'MyDevelopers');
  const [isCollapseBtnDisabled, setIsCollapseBtnDisabled] = useState(false);
  const [isMyDevPanelExpanded, updateIsMyDevPanelExpanded] = useSuperHubStore(s => [
    s.isMyDevPanelExpanded,
    s.updateIsMyDevPanelExpanded,
  ]);

  // On viewport resizing, collapse the panel when viewport height is <= 450px
  // Note: we do this w/ JS so the state is updated and kept-in-sync in the store
  useEffect(() => {
    function handleResize() {
      const isSmallViewport = window.innerHeight <= PANEL_MIN_COLLAPSE_HEIGHT;

      if (!isMyDevPanelExpanded && isSmallViewport) {
        updateIsMyDevPanelExpanded();
      }

      setIsCollapseBtnDisabled(isSmallViewport);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isMyDevPanelExpanded, updateIsMyDevPanelExpanded]);

  return (
    <div
      className={bem('-layout', `-layout${isMyDevPanelExpanded ? '_expanded' : ''}`)}
      data-testid="my-developers-panel"
    >
      <BasePanel label="My Developers" noJS={false} rootClassName={bem('-panel')}>
        <div className={bem('-container')}>
          <Switch>
            <Route path="/:action(my-developers)/:type(setup)">
              <Flex align="center" justify="center" style={{ height: '100%' }}>
                🚧 My Developers - Onboarding coming soon! 🚧
              </Flex>
            </Route>
            <Route path="/:action(my-developers)/:type(key|segment|user)?/:identifier?">
              <TopBar disableCollapseButton={isCollapseBtnDisabled} />
              <MyDevelopers isHub />
            </Route>
          </Switch>
        </div>
      </BasePanel>
    </div>
  );
}

export default MyDevelopersPanel;

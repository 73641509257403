import type { ProjectSettingsFormValues } from '..';
import type { MouseEventHandler } from 'react';
import type { FieldArrayWithId } from 'react-hook-form';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import styles from './index.module.scss';

export default function GlossaryTerm({
  disabled,
  onEdit,
  term,
}: {
  disabled: boolean;

  onEdit: MouseEventHandler<HTMLButtonElement>;
  term: FieldArrayWithId<ProjectSettingsFormValues, 'glossary'>;
}) {
  const bem = useClassy(styles, 'Glossary');

  return (
    <button className={bem('-term')} disabled={disabled} onClick={onEdit} type="button">
      <span className={bem('-term-name')}>{term.term}</span>
      <Flex className={bem('-term-definition')} gap="sm" justify="start" layout="col">
        <span>{term.definition}</span>
        <Flex align="center" className={bem('-term-usage')} gap="xs" justify="start">
          Usage: <code className={bem('-term-preview')}>{`<Glossary>${term.term}</Glossary>`}</code>
        </Flex>
      </Flex>
    </button>
  );
}

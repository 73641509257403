import React, { useContext } from 'react';

import { ProjectContext, type ProjectContextValue } from '@core/context';

import { Page } from '../shared';

import GitConnectionChooseRepo from './ChooseRepo';
import GitConnectionSetup from './Setup';

function GitConnection() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const provider = project.git.sync.connectedRepository?.provider || 'github';
  const connections = project.git.sync[provider];

  const isConnected = !!(connections && connections.length && provider);

  return (
    <Page renderActionSlot={!!isConnected}>{isConnected ? <GitConnectionChooseRepo /> : <GitConnectionSetup />}</Page>
  );
}

export default GitConnection;

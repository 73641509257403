import { uniqueSlug } from '@readme/server-shared/slugs'; // eslint-disable-line readme-internal/no-restricted-imports
import debug from 'debug';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { ProjectContext, UserContext } from '@core/context';
import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import useReadmeApi, { fetcher } from '@core/hooks/useReadmeApi';
import { useMetricsStore } from '@core/store';
import type { Segment } from '@core/types/metrics';
import { omit } from '@core/utils/lodash-micro';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Notification, { notify } from '@ui/Notification';

import SegmentPill from './SegmentPill';
import classes from './style.module.scss';

const Segments = () => {
  const bem = useClassy(classes, 'Segments');
  const activeSegmentRef = useRef<HTMLElement | null>(null);
  const recentRequestsRef = useRef<HTMLElement | null>(null);

  const [
    segments,
    tableColumns,
    filters,
    activeSegment,
    hasChangedFromInitialFilters,
    hasChangedFromActiveSegment,
    segmentQueryParams,
    updateSegments,
  ] = useMetricsStore(s => [
    s.myDevelopers.segments,
    s.myDevelopers.tableColumns,
    s.myDevelopers.filters,
    s.myDevelopers.activeSegment,
    s.myDevelopers.getHasChangedFromInitialFilters(),
    s.myDevelopers.getHasChangedFromActiveSegment(),
    s.myDevelopers.getSegmentQueryParams(),
    s.myDevelopers.updateSegments,
  ]);

  const { buildUrl, navigate } = useMyDevelopers();

  const { project } = useContext(ProjectContext);
  const userContext = useContext(UserContext) || {};

  const { subdomain } = project as { subdomain: string };

  /**
   * UserContext currently has incorrect expected type definition
   * So we need ts-ignores here until we fix longterm
   */

  // @ts-ignore
  const userEmail = userContext?.email;
  // @ts-ignore
  const userName = userContext?.name;

  const segmentsBaseUrl = `/${subdomain}/api-next/v2/segments`;
  const { data: segmentsData, mutate } = useReadmeApi<Segment[]>(segmentsBaseUrl);

  const currentSegments = useMemo(() => {
    return segments || [];
  }, [segments]);

  const existingSegmentSlugs = useMemo(() => {
    return currentSegments.map(({ slug }) => slug);
  }, [currentSegments]);

  // Set fetched segments in store
  useEffect(() => {
    if (segmentsData) {
      updateSegments(segmentsData);
    }
  }, [segmentsData, updateSegments]);

  useEffect(() => {
    // If active segment is set, maybe scroll into view
    activeSegmentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [activeSegment, activeSegmentRef]);

  const showErrorNotification = useCallback((error: unknown, message: string) => {
    // Log error w/ debug
    debug('readme:mydevelopers')(error);
    notify(<Notification kind="error">{message}</Notification>);
  }, []);

  const createNewSegment = useCallback(async () => {
    const title = `New Segment ${String(currentSegments.length + 1)}`;

    const segment = {
      emoji: '🦉',
      title,
      columns: tableColumns,
      search: segmentQueryParams,
    } as Partial<Segment>;

    // For the optimistic UI, we need to push a temp ID
    // These will then be resolved in mutate below after request
    const tempId = uuid();
    const optimisticData = cloneDeep(currentSegments);
    const optimisticSegment = {
      ...segment,
      id: tempId,
      slug: uniqueSlug(title, existingSegmentSlugs),
      lastUpdated: {
        email: userEmail,
        name: userName,
        updatedAt: new Date().toLocaleString(),
      },
    };

    optimisticData.push(optimisticSegment as Segment);

    let url = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: optimisticSegment.slug });

    // We'll optimistically navigate to the segment slug before creation request finishes
    navigate(url);

    try {
      /**
       * We can use mutate below with optimisticData to optimistically update the UI
       * and skip revalidation since this is a simple add on segments
       */
      mutate(
        async () => {
          const newSegment = (await fetcher(segmentsBaseUrl, {
            method: 'POST',
            body: JSON.stringify(segment),
          })) as Segment;

          // If for some reason the created segment returns a slug that doesn't match optimistic UI slug,
          // we'll renavigate
          if (optimisticSegment.slug !== newSegment.slug) {
            url = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: newSegment.slug });
            navigate(url);
          }

          const copy = cloneDeep(currentSegments);
          copy.push(newSegment);

          return copy;
        },
        {
          optimisticData,
          revalidate: false,
          rollbackOnError: true,
        },
      );
    } catch (error) {
      // Show toast on any API errors
      showErrorNotification(error, 'There was a problem creating Segment, please try again.');
    }
  }, [
    buildUrl,
    currentSegments,
    existingSegmentSlugs,
    mutate,
    navigate,
    segmentQueryParams,
    segmentsBaseUrl,
    showErrorNotification,
    tableColumns,
    userEmail,
    userName,
  ]);

  const deleteSegment = useCallback(
    async (segment: Segment) => {
      const deleteSegmentUrl = `${segmentsBaseUrl}/${segment.id}`;
      const optimisticData = currentSegments.filter(s => s.id !== segment.id);

      const url = buildUrl();
      // When removing a segment, we'll reset back to Recent Requests
      navigate(url);

      try {
        /**
         * We can use mutate below with optimisticData to optimistically update the UI
         * and skip revalidation since this is a simple deletion on segments
         */
        await mutate(
          async () => {
            (await fetcher(deleteSegmentUrl, {
              method: 'DELETE',
            })) as Segment;

            return optimisticData;
          },
          {
            optimisticData,
            revalidate: false,
            rollbackOnError: true,
          },
        );
      } catch (error) {
        // Show toast on any API errors
        showErrorNotification(error, 'There was a problem deleting Segment, please try again.');
      }

      // Maybe scroll 'Recent Requests' back into view
      recentRequestsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    },
    [buildUrl, currentSegments, mutate, navigate, segmentsBaseUrl, showErrorNotification],
  );

  const updateSegment = useCallback(
    async segment => {
      const patchSegmentUrl = `${segmentsBaseUrl}/${segment.id}`;
      const isActive = segment.id === activeSegment?.id;

      const optimisticData = cloneDeep(currentSegments);
      const indexOfSegmentToUpdate = currentSegments.findIndex(s => s.id === segment.id);
      const orderHasChanged = segment.order !== currentSegments[indexOfSegmentToUpdate].order;
      const titleHasChanged = segment.title !== currentSegments[indexOfSegmentToUpdate].title;

      if (orderHasChanged) {
        // Remove the segment and reinsert at new position
        optimisticData.splice(indexOfSegmentToUpdate, 1);
        optimisticData.splice(segment.order, 0, segment);

        // Update order for all segments
        optimisticData.forEach((seg, index) => {
          seg.order = index;
        });
      } else {
        optimisticData[indexOfSegmentToUpdate] = segment;
      }

      let optimisticSlug: string;

      // Only navigate to new slug if segment is active
      const shouldNavigateToNewSlug = isActive && titleHasChanged;

      if (shouldNavigateToNewSlug) {
        optimisticSlug = uniqueSlug(segment.title, existingSegmentSlugs);
        const url = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: optimisticSlug });
        navigate(url);
      }

      try {
        /**
         * Unlike creation and deletion of segments, we DO want to revalidate below
         * in order to ensure the order returned from segments is accurate in UI
         */
        mutate(
          async () => {
            const updatedSegment = (await fetcher(patchSegmentUrl, {
              method: 'PATCH',
              // When updating, we can omit a few segment values
              body: JSON.stringify(omit(segment, ['id', 'lastUpdated', 'slug'])),
            })) as Segment;

            // If for some reason the updated segment returns a slug that doesn't match optimistic UI slug,
            // we'll renavigate
            if (shouldNavigateToNewSlug && optimisticSlug !== updatedSegment.slug) {
              const url = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: updatedSegment.slug });
              navigate(url);
            }

            if (orderHasChanged) {
              return produce(currentSegments, draft => {
                draft.splice(indexOfSegmentToUpdate, 1);
                draft.splice(updatedSegment.order, 0, updatedSegment);
                // Update order for all segments
                draft.forEach((seg, index) => {
                  seg.order = index;
                });
              });
            }

            return produce(currentSegments, draft => {
              draft[indexOfSegmentToUpdate] = updatedSegment;
            });
          },
          {
            optimisticData,
            revalidate: true,
            rollbackOnError: true,
          },
        );
      } catch (error) {
        // Show toast on any API errors
        showErrorNotification(error, 'There was a problem updating Segment, please try again.');
      }
    },
    [
      activeSegment,
      buildUrl,
      currentSegments,
      existingSegmentSlugs,
      mutate,
      navigate,
      segmentsBaseUrl,
      showErrorNotification,
    ],
  );

  const handleNavigateToSegment = useCallback(
    segment => {
      const url = buildUrl({ type: MyDevelopersSubrouteType.Segment, identifier: segment.slug });
      navigate(url);
    },
    [buildUrl, navigate],
  );

  const handleNavigateToDefaultView = useCallback(() => {
    const url = buildUrl();
    navigate(url);
  }, [buildUrl, navigate]);

  const isAddSegmentDisabled = useMemo(() => {
    return filters.demo || !hasChangedFromInitialFilters || (!!activeSegment && !hasChangedFromActiveSegment);
  }, [activeSegment, hasChangedFromInitialFilters, hasChangedFromActiveSegment, filters.demo]);

  return (
    <>
      <Flex className={bem('&')} gap="xs" justify="start">
        <Flex className={bem('-segmentRow')} gap="xs" justify="start">
          <Button
            ref={recentRequestsRef}
            circular
            kind={hasChangedFromInitialFilters || !!activeSegment ? 'minimum' : 'primary'}
            onClick={handleNavigateToDefaultView}
            outline
            size="sm"
          >
            <Icon name="clock" />
            Recent Requests
          </Button>

          {currentSegments.map((s, index) => (
            <div
              key={s.id}
              ref={el => {
                if (s.id === activeSegment?.id) {
                  activeSegmentRef.current = el;
                }
              }}
            >
              <SegmentPill
                deleteSegment={deleteSegment}
                isActive={s.id === activeSegment?.id}
                isFirst={index === 0}
                isLast={index === currentSegments.length - 1}
                navigateToSegment={handleNavigateToSegment}
                segment={s}
                updateSegment={updateSegment}
              />
            </div>
          ))}
        </Flex>

        <Button
          circular
          disabled={isAddSegmentDisabled}
          kind={hasChangedFromInitialFilters ? 'primary' : 'minimum'}
          onClick={createNewSegment}
          outline={isAddSegmentDisabled}
          size="sm"
        >
          <Icon name="plus" />
          Add Segment
        </Button>
      </Flex>
    </>
  );
};

export default Segments;

import Tippy from '@tippyjs/react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { BaseUrlContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { DateRangeOptions } from '@core/types/metrics';
import cap from '@core/utils/capitalize';
import { hideOnEsc } from '@core/utils/tippy';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuHeader, MenuItem } from '@ui/Menu';
import { PricingTooltip, PricingText } from '@ui/Metrics/PricingTooltip';
import { PricingTooltipType } from '@ui/Metrics/PricingTooltip/types';
import Tooltip from '@ui/Tooltip';

import CustomRangeTooltip from './CustomRangeTooltip';
import classes from './style.module.scss';

export interface MetricsDateRangeProps {
  /** Whether to have date range options in a dropdown */
  isDropdown?: boolean;
  /** Whether date range is being used within MyDevelopers view (so it knows where to pull slice date range values from) */
  isMyDevelopers?: boolean;
  /** Whether to show the "Try It Data" badge */
  showTryItBadge?: boolean;
}

const MetricsDateRange = ({ isDropdown, isMyDevelopers, showTryItBadge }: MetricsDateRangeProps) => {
  const bem = useClassy(classes, 'MetricsDateRange');
  const tooltipRef = useRef(null);
  const customRangeRef = useRef(null);

  const [
    updateQuery,
    resetQuery,
    customerUsage,
    selectedDateRangeKey,
    dateRanges,
    myDevSelectedDateRangeKey,
    updateDateRange,
    filters,
  ] = useMetricsStore(s => [
    s.updateQuery,
    s.resetQuery,
    s.customerUsage,
    s.selectedDateRangeKey,
    s.dateRanges,
    s.myDevelopers.getSelectedDateRangeKey(),
    s.myDevelopers.updateDateRange,
    s.myDevelopers.filters,
  ]);

  const baseUrl = useContext(BaseUrlContext);
  const [showAddOnPopover, setShowAddOnPopover] = useState(false);
  const [showCustomRangeTooltip, setShowCustomRangeTooltip] = useState(false);

  const resetTableQuery = useCallback(() => {
    // Reset any table query params and update base query to include userSearch
    resetQuery('tableQuery');
  }, [resetQuery]);

  const handleRangeChange = useCallback(
    (key, range) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...rangePayload } = range;

      if (isMyDevelopers) {
        updateDateRange(key);
      } else {
        updateQuery('query', rangePayload);
        resetTableQuery();
      }
    },
    [isMyDevelopers, resetTableQuery, updateDateRange, updateQuery],
  );

  const handleCustomRangeChange = useCallback(
    (key, range) => {
      setShowCustomRangeTooltip(false);

      if (isMyDevelopers) {
        updateDateRange(key, range);
      } else {
        updateQuery('query', range);
        resetTableQuery();
      }
    },
    [isMyDevelopers, resetTableQuery, updateDateRange, updateQuery],
  );

  // Type of pricing tooltip to show
  const tooltipType = useMemo(() => {
    const overLimit = customerUsage.overLimit;
    return overLimit ? PricingTooltipType.Limit : PricingTooltipType.Metrics;
  }, [customerUsage.overLimit]);

  // If date ranges have not been established yet, return null
  if (!dateRanges) return null;

  /**
   * When in MyDevelopers route, we want to use the selectedDateRangeKey from the MyDevelopers context
   * that considers active segment filters instead of the selectedDateRangeKey from the MetricsContext
   */
  const selectedKey = isMyDevelopers ? myDevSelectedDateRangeKey : selectedDateRangeKey;

  // Whether all date range options are enabled
  const allDateRangesEnabled = Object.values(dateRanges as DateRangeOptions).every(option => option.enabled);

  return (
    <div className={bem('&')}>
      <div ref={tooltipRef} className={bem('-btn-group')}>
        {!!showTryItBadge && <Badge kind="alert">Try It Data</Badge>}

        {!allDateRangesEnabled && (
          <div onMouseEnter={() => setShowAddOnPopover(true)}>
            <PricingText type={tooltipType} />
          </div>
        )}

        <ButtonGroup
          className={bem(isMyDevelopers && isDropdown ? '_dark-on-black' : '')}
          highlightSelection={!!allDateRangesEnabled && !isDropdown}
          selectedKey={!isDropdown ? selectedKey : undefined}
        >
          {isDropdown
            ? [
                <Dropdown key="dropdown" clickInToClose justify="end" sticky>
                  <Button dropdown kind="secondary" outline size="sm">
                    {cap(selectedKey)}
                  </Button>
                  <Menu>
                    <MenuHeader>Timeframe</MenuHeader>
                    {Object.entries(dateRanges as DateRangeOptions).map(([key, range]) => (
                      <MenuItem
                        key={key}
                        active={selectedKey === key}
                        color={selectedKey === key ? 'blue' : undefined}
                        disabled={!range.enabled}
                        onClick={() => {
                          if (!range.enabled) {
                            setShowAddOnPopover(true);
                            return;
                          }

                          handleRangeChange(key, range);
                        }}
                      >
                        {cap(key)}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>,
                <Button
                  key="custom"
                  ref={customRangeRef}
                  className={bem('&', !allDateRangesEnabled && '-disabled-btn')}
                  kind="secondary"
                  onClick={() => {
                    if (!allDateRangesEnabled) {
                      setShowAddOnPopover(true);
                      return;
                    }

                    setShowCustomRangeTooltip(true);
                  }}
                  outline
                  size="sm"
                >
                  <Tooltip content="Custom range">
                    <Icon name="calendar" />
                  </Tooltip>
                </Button>,
              ]
            : [
                ...Object.entries(dateRanges as DateRangeOptions).map(([key, range]) => (
                  <Button
                    key={key}
                    className={bem(
                      '&',
                      !range.enabled && '-disabled-btn',
                      !range.enabled && selectedKey === key && '-active-disabled-btn',
                    )}
                    kind="secondary"
                    onClick={() => {
                      if (!range.enabled) {
                        setShowAddOnPopover(true);
                        return;
                      }

                      handleRangeChange(key, range);
                    }}
                    outline
                    size="sm"
                  >
                    {cap(key)}
                  </Button>
                )),
                <Button
                  key="custom"
                  ref={customRangeRef}
                  className={bem('&', !allDateRangesEnabled && '-disabled-btn')}
                  kind="secondary"
                  onClick={() => {
                    if (!allDateRangesEnabled) {
                      setShowAddOnPopover(true);
                      return;
                    }

                    setShowCustomRangeTooltip(true);
                  }}
                  outline
                  size="sm"
                >
                  <Tooltip content="Custom range">
                    <Icon name="calendar" />
                  </Tooltip>
                </Button>,
              ]}
        </ButtonGroup>
      </div>

      {/* Custom date range tooltip */}
      <Tippy
        appendTo={() => {
          // Fallback to document.body if tooltipRef is not available
          return tooltipRef.current || document.body;
        }}
        arrow={false}
        content={
          <CustomRangeTooltip
            activeFilters={filters}
            onApply={handleCustomRangeChange}
            onCancel={() => setShowCustomRangeTooltip(false)}
          />
        }
        interactive
        maxWidth={200}
        onClickOutside={() => {
          setShowCustomRangeTooltip(false);
        }}
        placement="bottom-end"
        plugins={[hideOnEsc]}
        reference={customRangeRef}
        visible={showCustomRangeTooltip}
      />

      {/* Pricing tooltip */}
      <Tippy
        arrow={false}
        content={<PricingTooltip link={`${baseUrl}/plans`} type={tooltipType} />}
        interactive
        maxWidth={400}
        offset={[-50, 10]}
        onClickOutside={() => {
          setShowAddOnPopover(false);
        }}
        placement="bottom-start"
        plugins={[hideOnEsc]}
        reference={tooltipRef}
        visible={showAddOnPopover}
      />
    </div>
  );
};

export default MetricsDateRange;
